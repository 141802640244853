/* src/components/Form.css */

.order-form {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .order-form h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* Personal Information Section */
  .personal-info {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the input fields */
  }
  
  .personal-info label {
    width: 100%;
    max-width: 500px; /* Limit the label width */
    margin-bottom: 15px;
    font-size: 1rem;
  }
  
  .personal-info input,
  .personal-info textarea {
    width: 100%;
    max-width: 500px; /* Limit the input width */
    padding: 8px;
    margin-top: 5px;
    font-size: 1rem;
  }
  
  /* Product Section */
  .product-section {
    display: flex;
    align-items: center;
    margin-top: 30px;
  }
  
  .product-quantity {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .product-quantity h3 {
    margin-bottom: 20px;
    font-size: 1.5rem;
  }
  
  .product-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    width: 300px;
  }
  
  .product-item label {
    width: 150px;
    font-size: 1rem;
  }
  
  .number-input {
    width: 150px;
    padding: 5px;
    font-size: 1rem;
  }
  
  /* Sizing Image */
  .sizing-image-container {
    flex: 1;
    text-align: center;
    margin-top: 20px;
  }
  
  .sizing-image {
    max-width: 100%;
    height: auto;
  }
  
  /* Discount Display */
  .discount-display {
    font-size: 1.2rem;
    font-weight: bold;
    margin-top: 20px;
    width: 300px;
    text-align: right;
  }
  
  /* Submit Button */
  button[type='submit'] {
    display: block;
    width: 100%;
    max-width: 500px; /* Limit the button width */
    padding: 10px;
    background-color: #caee94; /* Salt Green */
    color: #111111; /* Kala */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }
  
  button[type='submit']:disabled {
    background-color: #d5d5d5; /* Geyser */
    cursor: not-allowed;
  }
  
  /* Media Query for Mobile Devices */
  @media (max-width: 768px) {
    .product-section {
      flex-direction: column;
      align-items: center;
    }
  
    .sizing-image-container {
      margin-top: 20px;
    }
  
    .product-quantity,
    .discount-display {
      align-items: center;
    }
  
    .discount-display {
      text-align: center;
      width: auto;
    }
  
    /* Adjust input fields on mobile devices */
    .personal-info label,
    .personal-info input,
    .personal-info textarea {
      max-width: 90%; /* Limit input width to 90% of screen */
    }
  
    .personal-info input,
    .personal-info textarea {
      width: 100%; /* Input fields fill their container */
    }
  
    .product-item {
      width: 100%;
      max-width: 300px;
    }
  
    .number-input {
      width: 100%;
      max-width: 150px;
    }
  
    button[type='submit'] {
      max-width: 90%;
    }
  }
  
  