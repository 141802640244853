/* src/components/Contact.css */
.contact-page {
    padding: 40px 20px;
    text-align: center;
  }
  
  .contact-page h1 {
    font-family: 'HK Grotesk', sans-serif;
    margin-bottom: 20px;
  }
  
  .contact-page p {
    font-size: 1.2rem;
    color: #111111;
    margin-bottom: 10px;
  }
  