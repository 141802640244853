/* App.css */

/* Import Custom Fonts */
@font-face {
  font-family: 'HK Grotesk Medium';
  src: url('./fonts/HKGrotesk-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Tabac Glam Medium';
  src: url('./fonts/TabacGlamG3Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

/* Brand Colors */
:root {
  --kala: #2f0b0b;
  --salt: #ffffff;
  --geyser: #d5d5d5;
  --salt-green: #caee94;
  --buff: #faf3a1;
  --electric-lavender: #f1b9e7;
  --chambray: #41518c;
  --shocking-orange: #df6b31;
}

/* Global Styles */
body {
  margin: 0;
  font-family: 'HK Grotesk Medium', sans-serif;
  background-color: var(--salt);
  color: var(--kala);
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Tabac Glam Medium', serif;
}

.logo {
  width: 150px;
  height: auto;
}

/* Additional Styles */
/* ... rest of your styles ... */
