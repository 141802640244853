/* src/components/Footer.css */
.footer {
    background-color: #ffffff;
    padding: 20px;
    text-align: center;
    border-top: 1px solid #d5d5d5;
  }
  
  .footer-links {
    margin-bottom: 10px;
  }
  
  .footer-links a {
    margin: 0 10px;
    text-decoration: none;
    color: #111111;
    font-family: 'HK Grotesk', sans-serif;
  }
  
  .footer-links a:hover {
    color: #CAEE94; /* Salt Green */
  }
  
  .footer p {
    color: #111111;
    font-size: 0.9rem;
  }
  