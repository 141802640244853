/* src/components/Header.css */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #ffffff;
  }
  
  .logo-container {
    flex: 1;
  }
  
  .logo {
    width: 150px;
    height: auto;
  }
  
  .navigation {
    flex: 1;
    text-align: right;
  }
  
  .navigation a {
    margin-left: 20px;
    text-decoration: none;
    color: #111111;
    font-family: 'HK Grotesk', sans-serif;
  }
  
  .navigation a:hover {
    color: #CAEE94; /* Salt Green */
  }
  