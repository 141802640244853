/* src/components/Intro.css */
.intro {
    padding: 40px 20px;
    text-align: center;
    background-color: #f5f5f5;
  }
  
  .intro h1 {
    font-family: 'HK Grotesk', sans-serif;
    color: #111111;
    margin-bottom: 20px;
  }
  
  .intro p {
    font-size: 1.2rem;
    color: #111111;
    margin-bottom: 20px;
  }
  
  .images {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .images img {
    width: 300px;
    height: auto;
    border-radius: 8px;
  }
  